import { ApiResponse, IApi } from '../interfaces/iApi';
import { Candidate, ICandidates, ListCandidates, UpdateCandidate } from '../interfaces/iCandidates';
import { UploadedFile } from '../interfaces/iFiles';
import { IsNullOrEmpty } from '../utils/utils';

export class CandidatesService<T> implements ICandidates<Candidate | Candidate[] | UpdateCandidate> {
    private apiService: IApi<T>;
    constructor(type: { new(slug): IApi<T>; }, slug: string = '') {
        this.apiService = new type(slug);
    }

    async updateCandidate(candidateId: string, candidate: UpdateCandidate): Promise<Candidate> {
        var response = await this.apiService.update(candidate as any, candidateId);
        return response.data as Candidate;
    }

    async deleteCandidate(candidateId: string): Promise<void> {
        await this.apiService.delete(candidateId);
    }

    async getCandidateMedia(candidateId: string): Promise<UploadedFile> {
        var response = await this.apiService.get(`/${candidateId}/media`) as ApiResponse<UploadedFile>;
        return response.data;
    }
}

export function getAllCandidateSkills(candidates: Candidate[]): string[] {
    if (candidates.some(w => w.metadata))
        return [... new Set(candidates.flatMap(c => c.rank?.keywords))];
    else
        return [];
}

export function filterCandidates(filter: ListCandidates, candidates: Candidate[]): Candidate[] {

    if (filter.ids?.length > 0)
        candidates = candidates.filter(w => filter.ids.includes(w._id));

    if (filter.tags && filter.tags.length > 0)
        candidates = candidates.filter(w => w.rank?.keywords.some(t => filter.tags.includes(t)));

    if (!IsNullOrEmpty(filter.searchTerm))
        candidates = candidates.filter(w => w.metadata?.name.toLowerCase().includes(filter.searchTerm.toLowerCase()));

    if (filter.ranked)
        candidates = candidates.filter(w => w.rank != undefined);

    if (filter.justShortlist)
        candidates = candidates.filter(w => w.isShortlisted);

    candidates.sort((a, b) => (b.rank?.finalScore ?? 0) - (a.rank?.finalScore ?? 0));
    return candidates;
}